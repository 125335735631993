import { AnyAction, Dispatch } from 'redux';

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'constants/common.constants';

export const authMiddleware = () => {
  return (next: Dispatch) =>
    (action: AnyAction): AnyAction => {
      const result = next(action);

      switch (action.type) {
        case 'auth/sign-in/fulfilled':
        case 'auth/sign-up/fulfilled':
        case 'auth/refresh-token/fulfilled':
          const { accessToken, refreshToken } = action.payload;

          localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
          localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

          break;
        case 'auth/logout/fulfilled': {
          localStorage.removeItem(ACCESS_TOKEN_KEY);
          localStorage.removeItem(REFRESH_TOKEN_KEY);

          break;
        }
      }

      return result;
    };
};
