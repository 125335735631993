export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';

export const FETCHING_STATUSES = {
  IDLE: 'IDLE',
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const PENDING_STATUSES = [FETCHING_STATUSES.IDLE, FETCHING_STATUSES.FETCHING];

export const USER_ROLE_ADMIN = 1;

export const USER_ROLES_LABEL = {
  [USER_ROLE_ADMIN]: 'Admin',
};

export const SORT_DIRECTION_ASC = 'asc';
export const SORT_DIRECTION_DESC = 'desc';
