export default {
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontSize: 40,
        fontWeight: 500,
        lineHeight: 1.6,
      },
      h2: {
        fontSize: 32,
        fontWeight: 500,
        lineHeight: 1.5,
      },
      h3: {
        fontSize: 24,
        fontWeight: 500,
        lineHeight: 1.325,
      },
      body1: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.5,
      },
      body2: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.75,
      },
      caption: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.325,
      },
    },
  },
};
