import { createSlice } from '@reduxjs/toolkit';

import { FETCHING_STATUSES } from 'constants/common.constants';

import {
  createOrder as createOrderThunk,
  updateOrder as updateOrderThunk,
  getAllOrders as getAllOrdersThunk,
  getOrderById as getOrderByIdThunk,
} from './order.actions';
import initialState from './order.state';

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    clearErrors(state) {
      state.errors = null;
    },
    resetState(state) {
      state = initialState;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOrderThunk.pending, (state) => {
      state.creatingOrderStatus = FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(createOrderThunk.fulfilled, (state, action) => {
      state.creatingOrderStatus = FETCHING_STATUSES.SUCCESS;
      state.order.data = action.payload;
    });
    builder.addCase(createOrderThunk.rejected, (state, action) => {
      state.creatingOrderStatus = FETCHING_STATUSES.FAILURE;
      state.errors = action.payload.errors;
    });
    builder.addCase(updateOrderThunk.pending, (state) => {
      state.creatingOrderStatus = FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(updateOrderThunk.fulfilled, (state, action) => {
      state.creatingOrderStatus = FETCHING_STATUSES.SUCCESS;
      state.order.data = action.payload;
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(updateOrderThunk.rejected, (state, action) => {
      state.creatingOrderStatus = FETCHING_STATUSES.FAILURE;
      state.errors = action.payload.errors;
    });
    builder.addCase(getOrderByIdThunk.pending, (state) => {
      state.order.fetchingOrderStatus = FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(getOrderByIdThunk.fulfilled, (state, action) => {
      state.order.fetchingOrderStatus = FETCHING_STATUSES.SUCCESS;
      state.order.data = action.payload;
    });
    builder.addCase(getOrderByIdThunk.rejected, (state) => {
      state.order.fetchingOrderStatus = FETCHING_STATUSES.FAILURE;
    });

    builder.addCase(getAllOrdersThunk.pending, (state) => {
      state.orders.fetchingAllOrdersStatus = FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(getAllOrdersThunk.fulfilled, (state, action) => {
      state.orders.fetchingAllOrdersStatus = FETCHING_STATUSES.SUCCESS;
      state.orders.data = action.payload.data;
      state.orders.totalRecords = action.payload.totalRecords;
    });
    builder.addCase(getAllOrdersThunk.rejected, (state) => {
      state.orders.fetchingAllOrdersStatus = FETCHING_STATUSES.FAILURE;
    });
  },
});

const { actions, reducer } = orderSlice;

export const { clearErrors, resetState } = actions;

export default reducer;
