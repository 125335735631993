const green = {
  50: '#d4ffed',
  100: '#bfeacb',
  200: '#9dcfb0',
  300: '#7eb593',
  400: '#67a381',
  500: '#4e8e6b',
  600: '#427e5c',
  700: '#32694b',
  800: '#20553b',
  900: '#0d3f29',
};

export default green;
