import { ProducerResponseInterface } from '@nordic-firewood/api-contracts';

import { FETCHING_STATUSES } from 'constants/common.constants';

type ProducerState = {
  producer: {
    fetchingProducerStatus: string;
    data: ProducerResponseInterface | null;
  };
  producersByCompanyName: {
    fetchingProducersByCompanyNameStatus: string;
    data: ProducerResponseInterface[] | null;
    totalRecords: number;
    totalFilteredRecords: number;
  };
};

const initialState: ProducerState = {
  producer: {
    fetchingProducerStatus: FETCHING_STATUSES.IDLE,
    data: null,
  },
  producersByCompanyName: {
    fetchingProducersByCompanyNameStatus: FETCHING_STATUSES.IDLE,
    data: null,
    totalRecords: 0,
    totalFilteredRecords: 0,
  },
};

export default initialState;
