import { Theme } from '@mui/material';

import MuiButton from './MuiButton';
import MuiFormControl from './MuiFormControl';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiFormHelperText from './MuiFormHelperText';
import MuiFormLabel from './MuiFormLabel';
import MuiInputAdornment from './MuiInputAdornment';
import MuiMenuItem from './MuiMenuItem';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiSwitch from './MuiSwitch';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTooltip from './MuiTooltip';
import MuiTypography from './MuiTypography';

const ComponentsOverrides = (theme: Theme) => {
  return {
    ...MuiButton,
    ...MuiFormControl,
    ...MuiFormControlLabel,
    ...MuiFormHelperText,
    ...MuiFormLabel,
    ...MuiInputAdornment,
    ...MuiMenuItem,
    ...MuiOutlinedInput,
    ...MuiSwitch(theme),
    ...MuiTableCell(theme),
    ...MuiTableHead(theme),
    ...MuiTooltip,
    ...MuiTypography,
  };
};

export default ComponentsOverrides;
