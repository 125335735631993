import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getAllChains as getAllChainsRequest,
  getShopsByChainId as getShopsByChainIdRequest,
} from 'services/api/chain.api';
import {
  GetChainsRequestArgumentsType,
  GetChainsSuccessResponseType,
  GetShopsByChainIdRequestArgumentsType,
  GetShopsByChainIdSuccessResponseType,
} from 'types/chain.types';

export const getAllChains = createAsyncThunk<
  GetChainsSuccessResponseType,
  GetChainsRequestArgumentsType,
  null
>('chain/get-all', async (args) => {
  return await getAllChainsRequest(args);
});

export const getShopsByChainId = createAsyncThunk<
  GetShopsByChainIdSuccessResponseType,
  GetShopsByChainIdRequestArgumentsType,
  null
>('chain/shops-by-chain-id', async (args) => {
  return await getShopsByChainIdRequest(args);
});
