import { createSlice } from '@reduxjs/toolkit';

import { FETCHING_STATUSES } from 'constants/common.constants';

import {
  getProducerById as getProducerByIdThunk,
  getProducersByCompanyName as getProducersByCompanyNameThunk,
} from './producer.actions';
import initialState from './producer.state';

const producerSlice = createSlice({
  name: 'producer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProducerByIdThunk.pending, (state) => {
      state.producer.fetchingProducerStatus = FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(getProducerByIdThunk.fulfilled, (state, action) => {
      state.producer.fetchingProducerStatus = FETCHING_STATUSES.SUCCESS;
      state.producer.data = action.payload;
    });
    builder.addCase(getProducerByIdThunk.rejected, (state) => {
      state.producer.fetchingProducerStatus = FETCHING_STATUSES.FAILURE;
      state.producer.data = null;
    });

    builder.addCase(getProducersByCompanyNameThunk.pending, (state) => {
      state.producersByCompanyName.fetchingProducersByCompanyNameStatus =
        FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(getProducersByCompanyNameThunk.fulfilled, (state, action) => {
      state.producersByCompanyName.fetchingProducersByCompanyNameStatus = FETCHING_STATUSES.SUCCESS;
      state.producersByCompanyName.data = action.payload.data;
      state.producersByCompanyName.totalRecords = action.payload.totalRecords;
      state.producersByCompanyName.totalFilteredRecords = action.payload.totalFilteredRecords;
    });
    builder.addCase(getProducersByCompanyNameThunk.rejected, (state) => {
      state.producersByCompanyName.fetchingProducersByCompanyNameStatus = FETCHING_STATUSES.FAILURE;
      state.producersByCompanyName.data = null;
      state.producersByCompanyName.totalRecords = 0;
      state.producersByCompanyName.totalFilteredRecords = 0;
    });
  },
});

const { reducer } = producerSlice;

export default reducer;
