import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getTransportationCompanyById as getTransportationCompanyByIdRequest,
  getAllTransportationCompanies as getAllTransportationCompaniesRequest,
  getTransportationCompanyManagers as getTransportationCompanyManagersRequest,
} from 'services/api/transportation-company.api';
import {
  GetTransportationCompaniesRequestArgumentsType,
  GetTransportationCompaniesSuccessResponseType,
  getTransportationCompanyByIdRequestArgumentsInterface,
  GetTransportationCompanyByIdSuccessResponseType,
  GetTransportationCompanyManagersRequestArgumentsType,
  GetTransportationCompanyManagersSuccessResponseType,
} from 'types/transportation-company.types';

export const getTransportationCompanyById = createAsyncThunk<
  GetTransportationCompanyByIdSuccessResponseType,
  getTransportationCompanyByIdRequestArgumentsInterface,
  null
>('transportation-company/get-by-id', async (args) => {
  return await getTransportationCompanyByIdRequest(args);
});

export const getAllTransportationCompanies = createAsyncThunk<
  GetTransportationCompaniesSuccessResponseType,
  GetTransportationCompaniesRequestArgumentsType,
  null
>('transportation-company/get-all', async (args) => {
  return await getAllTransportationCompaniesRequest(args);
});

export const getTransportationCompanyManagers = createAsyncThunk<
  GetTransportationCompanyManagersSuccessResponseType,
  GetTransportationCompanyManagersRequestArgumentsType,
  null
>('transportation-company/managers', async (args) => {
  return await getTransportationCompanyManagersRequest(args);
});
