import authAxiosInstance from 'config/axios/auth.axios-instance';
import { BASE_CHAINS_URL } from 'constants/api.constants';
import {
  GetChainsRequestArgumentsType,
  GetChainsSuccessResponseType,
  GetShopsByChainIdRequestArgumentsType,
  GetShopsByChainIdSuccessResponseType,
} from 'types/chain.types';

export const getAllChains = async (
  args: GetChainsRequestArgumentsType,
): Promise<GetChainsSuccessResponseType> => {
  const { query } = args;

  return await authAxiosInstance.get(BASE_CHAINS_URL, {
    params: query,
  });
};

export const getShopsByChainId = async (
  args: GetShopsByChainIdRequestArgumentsType,
): Promise<GetShopsByChainIdSuccessResponseType> => {
  const { params, query } = args;

  return await authAxiosInstance.get(`${BASE_CHAINS_URL}/${params.id}/shops`, {
    params: query,
  });
};
