import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

const selectTransportationManagerState = (state: RootState) => state.transportationManager;

export const selectFetchingTransportationManagerStatus = createSelector(
  [selectTransportationManagerState],
  (state) => {
    return state.manager.fetchingTransportationManagerStatus;
  },
);
export const selectTransportationManager = createSelector(
  [selectTransportationManagerState],
  (state) => {
    return state.manager.data;
  },
);
