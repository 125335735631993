import { TransportationManagerResponseInterface } from '@nordic-firewood/api-contracts';

import { FETCHING_STATUSES } from 'constants/common.constants';

type TransportationManagerState = {
  manager: {
    fetchingTransportationManagerStatus: string;
    data: TransportationManagerResponseInterface | null;
  };
};

const initialState: TransportationManagerState = {
  manager: {
    fetchingTransportationManagerStatus: FETCHING_STATUSES.IDLE,
    data: null,
  },
};

export default initialState;
