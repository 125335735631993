import { createSlice } from '@reduxjs/toolkit';

import { FETCHING_STATUSES } from 'constants/common.constants';

import { getTransportationManagerById as getTransportationManagerByIdThunk } from './transportation-manager.actions';
import initialState from './transportation-manager.state';

const transportationManagerSlice = createSlice({
  name: 'transportation-manager',
  initialState,
  reducers: {
    resetManager(state) {
      state.manager.fetchingTransportationManagerStatus = FETCHING_STATUSES.IDLE;
      state.manager.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTransportationManagerByIdThunk.pending, (state) => {
      state.manager.fetchingTransportationManagerStatus = FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(getTransportationManagerByIdThunk.fulfilled, (state, action) => {
      state.manager.fetchingTransportationManagerStatus = FETCHING_STATUSES.SUCCESS;
      state.manager.data = action.payload;
    });
    builder.addCase(getTransportationManagerByIdThunk.rejected, (state) => {
      state.manager.fetchingTransportationManagerStatus = FETCHING_STATUSES.FAILURE;
      state.manager.data = null;
    });
  },
});

const { actions, reducer } = transportationManagerSlice;

export const { resetManager } = actions;

export default reducer;
