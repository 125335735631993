const brown = {
  50: '#ffffef',
  100: '#fffae9',
  200: '#fff5e3',
  300: '#ffe6d5',
  400: '#dbc2b1',
  500: '#bca494',
  600: '#917a6b',
  700: '#7c6658',
  800: '#5b4739',
  900: '#38261a',
};

export default brown;
