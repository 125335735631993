import authAxiosInstance from 'config/axios/auth.axios-instance';
import { BASE_PRODUCERS_URL } from 'constants/api.constants';
import {
  GetProducerByCompanyNameRequestArgumentsType,
  GetProducerByIdRequestArgumentsType,
  GetProducerByIdSuccessResponseType,
  GetProducersByCompanyNameSuccessResponseType,
} from 'types/producer.types';

export const getProducerById = async (
  args: GetProducerByIdRequestArgumentsType,
): Promise<GetProducerByIdSuccessResponseType> => {
  const { params } = args;

  return await authAxiosInstance(`${BASE_PRODUCERS_URL}/${params.id}`);
};

export const getProducersByCompanyName = async (
  args: GetProducerByCompanyNameRequestArgumentsType,
): Promise<GetProducersByCompanyNameSuccessResponseType> => {
  const { query } = args;

  return await authAxiosInstance.get(`${BASE_PRODUCERS_URL}/by-company-name`, { params: query });
};
