import { FETCHING_STATUSES } from 'constants/common.constants';
import { UserInterface } from 'types/auth.types';
import { FormFieldsErrors } from 'types/common.types';

type AuthorizationState = {
  fetchingSignInStatus: string;
  fetchingLogoutStatus: string;
  isAuthenticated: boolean;
  user: UserInterface | null;
  errors: FormFieldsErrors | null;
};

const initialState: AuthorizationState = {
  fetchingSignInStatus: FETCHING_STATUSES.IDLE,
  fetchingLogoutStatus: FETCHING_STATUSES.IDLE,
  isAuthenticated: false,
  user: null,
  errors: null,
};

export default initialState;
