import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

const selectShopState = (state: RootState) => state.shop;

export const selectFetchingShopStatus = createSelector([selectShopState], (state) => {
  return state.shop.fetchingShopStatus;
});
export const selectShop = createSelector([selectShopState], (state) => {
  return state.shop.data;
});
