import { grey } from '../colors';

export default {
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected, &.Mui-selected:focus': {
          backgroundColor: grey[200],
        },

        '&:hover': {
          backgroundColor: grey[100],

          '&.Mui-selected': {
            backgroundColor: grey[300],
          },
        },
      },
    },
  },
};
