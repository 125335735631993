import { createSlice } from '@reduxjs/toolkit';

import { FETCHING_STATUSES } from 'constants/common.constants';

import {
  getAllProducts as getAllProductsThunk,
  createProduct as createProductThunk,
  getProductsBySearch as getProductsBySearchThunk,
} from './product.actions';
import initialState from './product.state';

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    clearErrors(state) {
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createProductThunk.pending, (state) => {
      state.creatingProductStatus = FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(createProductThunk.fulfilled, (state) => {
      state.creatingProductStatus = FETCHING_STATUSES.SUCCESS;
    });
    builder.addCase(createProductThunk.rejected, (state, action) => {
      state.creatingProductStatus = FETCHING_STATUSES.FAILURE;
      state.errors = action.payload.errors;
    });
    builder.addCase(getAllProductsThunk.pending, (state) => {
      state.products.fetchingProductsStatus = FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(getAllProductsThunk.fulfilled, (state, action) => {
      state.products.fetchingProductsStatus = FETCHING_STATUSES.SUCCESS;
      state.products.data = action.payload.data;
      state.products.totalRecords = action.payload.totalRecords;
      state.products.totalFilteredRecords = action.payload.totalFilteredRecords;
    });
    builder.addCase(getAllProductsThunk.rejected, (state) => {
      state.products.fetchingProductsStatus = FETCHING_STATUSES.FAILURE;
    });
    builder.addCase(getProductsBySearchThunk.pending, (state) => {
      state.productsBySearch.fetchingProductsBySearchStatus = FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(getProductsBySearchThunk.fulfilled, (state, action) => {
      state.productsBySearch.fetchingProductsBySearchStatus = FETCHING_STATUSES.SUCCESS;
      state.productsBySearch.data = action.payload.data;
      state.productsBySearch.totalRecords = action.payload.totalRecords;
      state.productsBySearch.totalFilteredRecords = action.payload.totalFilteredRecords;
    });
    builder.addCase(getProductsBySearchThunk.rejected, (state) => {
      state.productsBySearch.fetchingProductsBySearchStatus = FETCHING_STATUSES.FAILURE;
    });
  },
});

const { actions, reducer } = productSlice;

export const { clearErrors } = actions;

export default reducer;
