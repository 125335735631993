import { green, grey } from '../colors';

export default {
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        '.MuiSvgIcon-root': {
          color: grey[600],
        },

        '&.Mui-disabled': {
          '.MuiSvgIcon-root': {
            color: grey[400],
          },
        },

        '.Mui-checked:not(.Mui-disabled)': {
          '.MuiSvgIcon-root': {
            color: green[800],
          },

          '& + .MuiFormControlLabel-label': {
            color: grey[800],
          },
        },

        '&:hover': {
          '&:not(.Mui-disabled)': {
            '.MuiSvgIcon-root': {
              color: grey[900],
            },

            '.MuiSwitch-track': {
              backgroundColor: grey[900],
            },

            '.MuiFormControlLabel-label': {
              color: grey[900],
            },

            '.Mui-checked': {
              '.MuiSvgIcon-root': {
                color: green[900],
              },

              '& + .MuiSwitch-track': {
                backgroundColor: green[900],
              },

              '& + .MuiFormControlLabel-label': {
                color: grey[900],
              },
            },
          },
        },
      },
      label: {
        color: grey[600],

        '&.Mui-disabled': {
          color: grey[400],
        },
      },
    },
  },
};
