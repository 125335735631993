import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

const selectProductState = (state: RootState) => state.product;

export const selectFetchingProductsStatus = createSelector([selectProductState], (product) => {
  return product.products.fetchingProductsStatus;
});
export const selectProducts = createSelector([selectProductState], (product) => {
  return product.products.data;
});
export const selectProductsTotalRecords = createSelector([selectProductState], (product) => {
  return product.products.totalRecords;
});
export const selectProductsFilteredTotalRecords = createSelector(
  [selectProductState],
  (product) => {
    return product.products.totalFilteredRecords;
  },
);

export const selectCreatingProductStatus = (state: RootState) =>
  state.product.creatingProductStatus;
export const selectErrors = (state: RootState) => state.product.errors;

export const selectFetchingProductsBySearchStatus = createSelector(
  [selectProductState],
  (product) => {
    return product.productsBySearch.fetchingProductsBySearchStatus;
  },
);
export const selectProductsBySearch = createSelector([selectProductState], (product) => {
  return product.productsBySearch.data;
});
export const selectProductsBySearchTotalRecords = createSelector(
  [selectProductState],
  (product) => {
    return product.products.totalRecords;
  },
);
export const selectProductsBySearchFilteredTotalRecords = createSelector(
  [selectProductState],
  (product) => {
    return product.products.totalFilteredRecords;
  },
);
