import { combineReducers } from '@reduxjs/toolkit';

import auth from './auth';
import chain from './chain';
import order from './order';
import producer from './producer';
import product from './product';
import shop from './shop';
import transportationCompany from './transportation-company';
import transportationManager from './transportation-manager';

const rootReducer = combineReducers({
  auth,
  chain,
  order,
  producer,
  product,
  shop,
  transportationCompany,
  transportationManager,
});

export default rootReducer;
