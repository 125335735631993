const breakpoints = {
  values: {
    xs: 360,
    sm: 600,
    md: 1024,
    lg: 1200,
    xl: 1440,
  },
};

export default breakpoints;
