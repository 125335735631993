import {
  TransportationCompanyResponseInterface,
  TransportationManagerResponseInterface,
} from '@nordic-firewood/api-contracts';

import { FETCHING_STATUSES } from 'constants/common.constants';

type TransportationCompanyState = {
  company: {
    fetchingTransportationCompanyStatus: string;
    data: TransportationCompanyResponseInterface | null;
  };
  companies: {
    fetchingTransportationCompaniesStatus: string;
    data: TransportationCompanyResponseInterface[] | null;
    totalRecords: number;
    totalFilteredRecords: number;
  };
  companyManagers: {
    fetchingCompanyManagersStatus: string;
    data: TransportationManagerResponseInterface[] | null;
  };
};

const initialState: TransportationCompanyState = {
  company: {
    fetchingTransportationCompanyStatus: FETCHING_STATUSES.IDLE,
    data: null,
  },
  companies: {
    fetchingTransportationCompaniesStatus: FETCHING_STATUSES.IDLE,
    data: null,
    totalRecords: 0,
    totalFilteredRecords: 0,
  },
  companyManagers: {
    fetchingCompanyManagersStatus: FETCHING_STATUSES.IDLE,
    data: null,
  },
};

export default initialState;
