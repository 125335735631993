import { brown, green, grey, red } from '../colors';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    dark: true;
  }

  interface ButtonPropsVariantOverrides {
    contrast: true;
  }
}

export default {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        borderRadius: 4,
        boxShadow: 'none',

        '&:hover': {
          boxShadow:
            '0px 3px 5px rgba(34, 36, 39, 0.2), 0px 5px 8px rgba(34, 36, 39, 0.14), 0px 1px 14px rgba(34, 36, 39, 0.12)',
        },

        '&:active': {
          boxShadow: 'none',
        },
      },
      sizeSmall: {
        height: 32,
        paddingTop: 4,
        paddingRight: 8,
        paddingBottom: 4,
        paddingLeft: 8,
      },
      sizeMedium: {
        height: 40,
        paddingTop: 8,
        paddingRight: 24,
        paddingBottom: 8,
        paddingLeft: 24,
      },
      contained: {
        color: grey[50],
      },
      containedPrimary: {
        '&:active': {
          backgroundColor: green[600],
        },
      },
      containedDark: {
        '&:active': {
          backgroundColor: grey[600],
        },
      },
      containedSecondary: {
        '&:active': {
          backgroundColor: brown[600],
        },
      },
      containedError: {
        '&:active': {
          backgroundColor: red[600],
        },
      },
      containedInherit: {
        color: green[800],
        backgroundColor: grey[50],

        '&:hover': {
          backgroundColor: grey[50],
        },

        '&:active': {
          backgroundColor: grey[200],
        },

        '&.Mui-disabled': {
          color: green[500],
          backgroundColor: green[700],
        },
      },
      outlinedPrimary: {
        '&:hover': {
          color: green[900],
          borderColor: green[900],
        },

        '&:active': {
          color: green[600],
          borderColor: green[600],
        },
      },
      outlinedDark: {
        '&:hover': {
          color: grey[900],
          borderColor: grey[900],
        },

        '&:active': {
          color: grey[600],
          borderColor: grey[600],
        },
      },
      outlinedSecondary: {
        '&:hover': {
          color: brown[900],
          borderColor: brown[900],
        },

        '&:active': {
          color: brown[600],
          borderColor: brown[600],
        },
      },
      outlinedError: {
        '&:hover': {
          color: red[900],
          borderColor: red[900],
        },

        '&:active': {
          color: red[600],
          borderColor: red[600],
        },
      },
      outlinedInherit: {
        color: grey[50],
        borderColor: grey[50],
        backgroundColor: green[800],

        '&:hover': {
          color: grey[0],
          borderColor: grey[0],
        },

        '&:active': {
          color: grey[200],
          borderColor: grey[200],
        },

        '&.Mui-disabled': {
          color: green[700],
          borderColor: green[700],
          backgroundColor: green[800],
        },
      },
      text: {
        border: `1px solid transparent`,
        backgroundColor: 'transparent',

        '&:hover': {
          boxShadow: 'none',
        },

        '&.Mui-disabled': {
          color: grey[500],
        },
      },
      textPrimary: {
        '&:hover': {
          color: green[900],
          borderColor: green[900],
        },

        '&:active': {
          color: green[600],
          borderColor: green[600],
        },
      },
      textDark: {
        '&:hover': {
          color: grey[900],
          borderColor: grey[900],
        },

        '&:active': {
          color: grey[600],
          borderColor: grey[600],
        },
      },
      textSecondary: {
        '&:hover': {
          color: brown[900],
          borderColor: brown[900],
        },

        '&:active': {
          color: brown[600],
          borderColor: brown[600],
        },
      },
      textError: {
        '&:hover': {
          color: red[900],
          borderColor: red[900],
        },

        '&:active': {
          color: red[600],
          borderColor: red[600],
        },
      },
      textInherit: {
        color: green[100],

        '&:hover': {
          color: green[50],
          borderColor: green[50],
        },

        '&:active': {
          color: green[300],
          borderColor: green[300],
        },

        '&.Mui-disabled': {
          color: green[600],
        },
      },
      contrast: {
        backgroundColor: grey[200],
      },
      contrastPrimary: {
        color: green[800],

        '&:hover': {
          color: grey[50],
          backgroundColor: green[800],
        },

        '&:active': {
          color: grey[50],
          backgroundColor: green[600],
        },
      },
      contrastDark: {
        color: grey[800],

        '&:hover': {
          color: grey[50],
          backgroundColor: grey[800],
        },

        '&:active': {
          color: grey[50],
          backgroundColor: grey[600],
        },
      },
      contrastSecondary: {
        color: brown[800],

        '&:hover': {
          color: grey[50],
          backgroundColor: brown[800],
        },

        '&:active': {
          color: grey[50],
          backgroundColor: brown[600],
        },
      },
      contrastError: {
        color: red[800],

        '&:hover': {
          color: grey[50],
          backgroundColor: red[800],
        },

        '&:active': {
          color: grey[50],
          backgroundColor: red[600],
        },
      },
      contrastInherit: {
        color: grey[50],
        backgroundColor: green[600],

        '&:hover': {
          backgroundColor: green[400],
        },

        '&:active': {
          backgroundColor: green[700],
        },

        '&.Mui-disabled': {
          color: green[500],
          backgroundColor: green[700],
        },
      },
    },
  },
};
