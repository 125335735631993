import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getAllProducts as getAllProductsRequest,
  createProduct as createProductRequest,
  getProductsBySearch as getProductsBySearchRequest,
} from 'services/api/product.api';
import { ResponseFailureInterface } from 'types/common.types';
import {
  CreateProductFailureResponseType,
  CreateProductRequestArgumentsType,
  CreateProductSuccessResponseType,
  GetProductsBySearchRequestArgumentsType,
  GetProductsBySearchSuccessResponseType,
  GetProductsRequestArgumentsType,
  GetProductsSuccessResponseType,
} from 'types/product.types';

export const getAllProducts = createAsyncThunk<
  GetProductsSuccessResponseType,
  GetProductsRequestArgumentsType,
  null
>('product/get-all', async (args) => {
  return await getAllProductsRequest(args);
});

export const createProduct = createAsyncThunk<
  CreateProductSuccessResponseType,
  CreateProductRequestArgumentsType,
  CreateProductFailureResponseType
>('product/create', async (args, { rejectWithValue }) => {
  try {
    return await createProductRequest(args);
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});

export const getProductsBySearch = createAsyncThunk<
  GetProductsBySearchSuccessResponseType,
  GetProductsBySearchRequestArgumentsType,
  null
>('products/get-by-search', async (args) => {
  return await getProductsBySearchRequest(args);
});
