import { Container, Box as MUIBox } from '@mui/material';
import { styled } from '@mui/system';

import BackgroundImagePath from 'assets/images/public-layout-background.png';

export const Layout = styled(Container)(({ theme }) => ({
  height: '100%',
  backgroundImage: `url(${BackgroundImagePath})`,
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',

  [theme.breakpoints.up('sm')]: {
    paddingRight: 128,
    paddingLeft: 128,
  },
}));

export const Box = styled(MUIBox)({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
});
