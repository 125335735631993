import { Suspense, useState } from 'react';

import { BrowserRouter } from 'react-router-dom';

import Providers from 'components/Providers';
import store from 'store';

import Auth from './auth/Auth';

import i18n from '../i18n';

const App = () => {
  const [isSidebarNarrowed, setIsSidebarNarrowed] = useState<boolean>(false);

  const setSidebarView = () => setIsSidebarNarrowed(!isSidebarNarrowed);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Providers
        i18n={i18n}
        store={store}
        snackbarProviderProps={{ preventDuplicate: true, autoHideDuration: 3000 }}
        layoutProviderProps={{ isSidebarNarrowed, setSidebarView }}
      >
        <BrowserRouter>
          <Auth />
        </BrowserRouter>
      </Providers>
    </Suspense>
  );
};

export default App;
