const blue = {
  50: '#dff6ff',
  100: '#b7e1ff',
  200: '#8ccfff',
  300: '#5dbaff',
  400: '#3ba8ff',
  500: '#0d99fe',
  600: '#0d8aef',
  700: '#0d8aef',
  800: '#0d68cb',
  900: '#074aac',
};

export default blue;
