import { ReactElement, useMemo } from 'react';

import { CssBaseline } from '@mui/material';
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';

import componentsOverride from './overrides';
import { breakpoints, palette, typography } from './theme';

import 'typeface-inter';

type Args = {
  children: ReactElement;
};

const ThemeProvider = (args: Args) => {
  const { children } = args;

  const themeOptions = useMemo(
    () => ({
      palette,
      breakpoints,
      typography,
    }),
    [],
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
