const grey = {
  0: '#fff',
  50: '#fbfdff',
  100: '#f5f7fb',
  200: '#eff1f6',
  300: '#e1e3e7',
  400: '#bdbfc3',
  500: '#9fa1a5',
  600: '#76787b',
  700: '#626467',
  800: '#434548',
  900: '#222427',
};

export default grey;
