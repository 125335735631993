import authAxiosInstance from 'config/axios/auth.axios-instance';
import { URL_PRODUCT } from 'constants/api.constants';
import {
  CreateProductRequestArgumentsType,
  CreateProductSuccessResponseType,
  GetProductsBySearchRequestArgumentsType,
  GetProductsBySearchSuccessResponseType,
  GetProductsRequestArgumentsType,
  GetProductsSuccessResponseType,
} from 'types/product.types';

export const getAllProducts = async (
  args: GetProductsRequestArgumentsType,
): Promise<GetProductsSuccessResponseType> => {
  const { query } = args;

  return await authAxiosInstance.get(URL_PRODUCT.GET_ALL, { params: query });
};

export const createProduct = async (
  args: CreateProductRequestArgumentsType,
): Promise<CreateProductSuccessResponseType> => {
  const { body } = args;

  return await authAxiosInstance.post(URL_PRODUCT.CREATE, body);
};

export const getProductsBySearch = async (
  args: GetProductsBySearchRequestArgumentsType,
): Promise<GetProductsBySearchSuccessResponseType> => {
  const { query } = args;

  return await authAxiosInstance.get(URL_PRODUCT.GET_BY_SEARCH, { params: query });
};
