import { blue, brown, green, grey, red, yellow } from '../colors';

declare module '@mui/material/styles' {
  interface Palette {
    dark: Palette['primary'];
  }
  interface PaletteOptions {
    dark: PaletteOptions['primary'];
  }
}

const GREY = {
  ...grey,
};

const PRIMARY = {
  light: green[700],
  main: green[800],
  dark: green[900],

  contrastText: '#fff',
};

const SECONDARY = {
  light: brown[700],
  main: brown[800],
  dark: brown[900],

  contrastText: '#fff',
};

const DARK = {
  light: grey[700],
  main: grey[800],
  dark: grey[900],

  contrastText: '#fff',
};

const ERROR = {
  light: red[700],
  main: red[800],
  dark: red[900],

  contrastText: '#fff',
};

const WARNING = {
  light: yellow[700],
  main: yellow[800],
  dark: yellow[900],

  contrastText: '#fff',
};

const INFO = {
  light: blue[700],
  main: blue[800],
  dark: blue[900],

  contrastText: '#fff',
};

const palette = {
  primary: PRIMARY,
  secondary: SECONDARY,
  dark: DARK,
  error: ERROR,
  warning: WARNING,
  info: INFO,
  grey: GREY,
  text: { primary: GREY[800] },
};

export default palette;
