import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

const selectProducerState = (state: RootState) => state.producer;

export const selectFetchingProducerStatus = createSelector([selectProducerState], (state) => {
  return state.producer.fetchingProducerStatus;
});
export const selectProducer = createSelector([selectProducerState], (state) => {
  return state.producer.data;
});

export const selectFetchingProducersByCompanyNameStatus = createSelector(
  [selectProducerState],
  (state) => {
    return state.producersByCompanyName.fetchingProducersByCompanyNameStatus;
  },
);
export const selectProducersByCompanyName = createSelector([selectProducerState], (state) => {
  return state.producersByCompanyName.data;
});
