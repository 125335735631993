import { ShopResponseInterface } from '@nordic-firewood/api-contracts';

import { FETCHING_STATUSES } from 'constants/common.constants';

type ShopState = {
  shop: {
    fetchingShopStatus: string;
    data: ShopResponseInterface;
  };
};

const initialState: ShopState = {
  shop: {
    fetchingShopStatus: FETCHING_STATUSES.IDLE,
    data: null,
  },
};

export default initialState;
