import { grey } from '../colors';

export default {
  MuiFormLabel: {
    styleOverrides: {
      root: {
        width: '100%',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '24px',
        color: grey[600],
        transform: 'none !important',
      },
    },
  },
};
