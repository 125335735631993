import axios from 'axios';

const baseAxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  timeout: 5000,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' },
});

baseAxiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    throw error.response.data;
  },
);

export default baseAxiosInstance;
