import { Theme } from '@mui/material';

import { grey } from '../colors';

export default (theme: Theme) => ({
  MuiSwitch: {
    defaultProps: {
      size: 'small' as const,
    },
    styleOverrides: {
      root: {
        padding: 12,

        '.MuiSwitch-switchBase': {
          padding: 2,

          '&.Mui-checked': {
            color: grey[0],

            '& + .MuiSwitch-track': {
              opacity: 1,
            },
          },

          '&.Mui-disabled': {
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: grey[400],
            },
          },
        },
      },
      switchBase: {
        top: 12,
        left: 12,
      },
      sizeSmall: {
        width: 44,
        height: 34,

        '.MuiSwitch-switchBase': {
          '&.Mui-checked': {
            transform: 'translateX(10px)',
          },
        },

        '.MuiSwitch-thumb': {
          width: 6,
          height: 6,
        },
      },
      sizeMedium: {
        '.MuiSwitch-switchBase': {
          '&.Mui-checked': {
            transform: 'translateX(20px)',
          },
        },

        '.MuiSwitch-thumb': {
          width: 10,
          height: 10,
        },
      },
      thumb: {
        boxShadow: 'none',
        backgroundColor: grey[0],
        transition: theme.transitions.create(['width'], {
          duration: 200,
        }),
      },
      track: {
        opacity: 1,
        backgroundColor: grey[600],
      },
    },
  },
});
