import { createSlice } from '@reduxjs/toolkit';

import { FETCHING_STATUSES } from 'constants/common.constants';

import { getAllChains as getAllChainsThunk, getShopsByChainId } from './chain.actions';
import initialState from './chain.state';

const chainSlice = createSlice({
  name: 'chain',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllChainsThunk.pending, (state) => {
      state.chains.fetchingChainsStatus = FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(getAllChainsThunk.fulfilled, (state, action) => {
      state.chains.fetchingChainsStatus = FETCHING_STATUSES.SUCCESS;
      state.chains.data = action.payload.data;
      state.chains.totalRecords = action.payload.totalRecords;
      state.chains.totalFilteredRecords = action.payload.totalFilteredRecords;
    });
    builder.addCase(getAllChainsThunk.rejected, (state) => {
      state.chains.fetchingChainsStatus = FETCHING_STATUSES.FAILURE;
      state.chains.data = null;
      state.chains.totalRecords = 0;
      state.chains.totalFilteredRecords = 0;
    });
    builder.addCase(getShopsByChainId.pending, (state) => {
      state.chainShops.fetchingChainShopsStatus = FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(getShopsByChainId.fulfilled, (state, action) => {
      state.chainShops.fetchingChainShopsStatus = FETCHING_STATUSES.SUCCESS;
      state.chainShops.data = action.payload.data;
      state.chainShops.totalRecords = action.payload.totalRecords;
      state.chainShops.totalFilteredRecords = action.payload.totalFilteredRecords;
    });
    builder.addCase(getShopsByChainId.rejected, (state) => {
      state.chainShops.fetchingChainShopsStatus = FETCHING_STATUSES.FAILURE;
      state.chainShops.data = null;
      state.chainShops.totalRecords = 0;
      state.chainShops.totalFilteredRecords = 0;
    });
  },
});

const { reducer } = chainSlice;

export default reducer;
