import { createAsyncThunk } from '@reduxjs/toolkit';

import { getTransportationManagerById as getTransportationManagerByIdRequest } from 'services/api/transportation-manager.api';
import {
  GetTransportationManagerByIdRequestArgumentsType,
  GetTransportationManagerByIdSuccessResponseType,
} from 'types/transportation-manager.types';

export const getTransportationManagerById = createAsyncThunk<
  GetTransportationManagerByIdSuccessResponseType,
  GetTransportationManagerByIdRequestArgumentsType,
  null
>('transportation/manager', async (args) => {
  return await getTransportationManagerByIdRequest(args);
});
