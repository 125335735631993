// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEng from 'locales/en/translations.json';

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  detection: {
    order: ['localStorage'],
  },
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translations: translationEng,
    },
  },
  defaultNS: 'translations',
  ns: ['translations'],
});

export default i18n;
