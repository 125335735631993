import axios from 'axios';

import { authorizationHeaderRequestInterceptor } from './interceptors';

const authAxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  timeout: 5000,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' },
});

authAxiosInstance.interceptors.request.use(authorizationHeaderRequestInterceptor);

authAxiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    throw error.response.data;
  },
);

export default authAxiosInstance;
