import { enqueueSnackbar } from 'notistack';
import { AnyAction, Dispatch } from 'redux';

export const notificationMiddleware = () => {
  return (next: Dispatch) =>
    (action: AnyAction): AnyAction => {
      const result = next(action);

      switch (action.type) {
        case 'order/create/rejected':
        case 'auth/sign-in/rejected': {
          const { message } = action.payload;

          if (message) {
            enqueueSnackbar(message, {
              variant: 'error',
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
              hideIconVariant: true,
            });
          }
          break;
        }
      }

      return result;
    };
};
