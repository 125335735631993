import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  createOrder as createOrderRequest,
  updateOrder as updateOrderRequest,
  getAllOrders as getAllOrdersRequest,
  getOrderById as getOrderByIdRequest,
} from 'services/api/order.api';
import { ResponseFailureInterface } from 'types/common.types';
import {
  CreateOrderFailureResponseType,
  CreateOrderRequestArgumentsType,
  CreateOrderSuccessResponseType,
  GetAllOrdersRequestArgumentsType,
  GetAllOrdersSuccessResponseType,
  GetOrderByIdRequestArgumentsType,
  GetOrderByIdSuccessResponseType,
  UpdateOrderFailureResponseType,
  UpdateOrderRequestArgumentsType,
  UpdateOrderSuccessResponseType,
} from 'types/order.types';

export const createOrder = createAsyncThunk<
  CreateOrderSuccessResponseType,
  CreateOrderRequestArgumentsType,
  CreateOrderFailureResponseType
>('order/create', async (args, { rejectWithValue }) => {
  try {
    return await createOrderRequest(args);
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});

export const updateOrder = createAsyncThunk<
  UpdateOrderSuccessResponseType,
  UpdateOrderRequestArgumentsType,
  UpdateOrderFailureResponseType
>('order/update', async (args, { rejectWithValue }) => {
  try {
    return await updateOrderRequest(args);
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});

export const getOrderById = createAsyncThunk<
  GetOrderByIdSuccessResponseType,
  GetOrderByIdRequestArgumentsType,
  null
>('order/get-by-id', async (args) => {
  return await getOrderByIdRequest(args);
});

export const getAllOrders = createAsyncThunk<
  GetAllOrdersSuccessResponseType,
  GetAllOrdersRequestArgumentsType,
  null
>('order/get-all', async (args) => {
  return await getAllOrdersRequest(args);
});
