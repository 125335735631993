import { createSlice } from '@reduxjs/toolkit';

import { FETCHING_STATUSES } from 'constants/common.constants';

import {
  signIn as signInThunk,
  logout as logoutThunk,
  refreshToken as refreshTokenThunk,
} from './auth.actions';
import initialState from './auth.state';

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearErrors(state) {
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInThunk.pending, (state) => {
      state.fetchingSignInStatus = FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(signInThunk.fulfilled, (state, action) => {
      state.fetchingSignInStatus = FETCHING_STATUSES.SUCCESS;
      state.isAuthenticated = true;
      state.user = action.payload.user;
    });
    builder.addCase(signInThunk.rejected, (state, action) => {
      state.fetchingSignInStatus = FETCHING_STATUSES.FAILURE;
      state.isAuthenticated = false;
      state.errors = action.payload.errors;
    });
    builder.addCase(logoutThunk.pending, (state) => {
      state.fetchingLogoutStatus = FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(logoutThunk.fulfilled, (state) => {
      state.fetchingLogoutStatus = FETCHING_STATUSES.SUCCESS;
      state.isAuthenticated = false;
      state.user = null;
    });
    builder.addCase(logoutThunk.rejected, (state) => {
      state.fetchingLogoutStatus = FETCHING_STATUSES.FAILURE;
    });
    builder.addCase(refreshTokenThunk.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    });
  },
});

const { actions, reducer } = authSlice;

export const { clearErrors } = actions;

export default reducer;
