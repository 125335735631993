import { createSlice } from '@reduxjs/toolkit';

import { FETCHING_STATUSES } from 'constants/common.constants';

import { getShopById as getShopByIdThunk } from './shop.actions';
import initialState from './shop.state';

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    resetShop(state) {
      state.shop.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShopByIdThunk.pending, (state) => {
      state.shop.fetchingShopStatus = FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(getShopByIdThunk.fulfilled, (state, action) => {
      state.shop.fetchingShopStatus = FETCHING_STATUSES.SUCCESS;
      state.shop.data = action.payload;
    });
    builder.addCase(getShopByIdThunk.rejected, (state) => {
      state.shop.fetchingShopStatus = FETCHING_STATUSES.FAILURE;
      state.shop.data = null;
    });
  },
});

const { actions, reducer } = shopSlice;

export const { resetShop } = actions;

export default reducer;
