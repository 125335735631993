export const URL_AUTH = {
  SIGN_IN: '/auth/sign-in',
  REFRESH_TOKEN: '/auth/refresh-token',
  LOGOUT: '/auth/logout',
};

export const URL_PRODUCT = {
  GET_ALL: '/products',
  GET_BY_SEARCH: '/products/by-search',
  CREATE: '/products',
};

export const URL_ORDER = {
  GET_ALL: '/orders',
  GET_BY_ID: '/orders',
  CREATE: '/orders',
  UPDATE: '/orders',
};

export const URL_FILE = {
  UPLOAD_IMAGE: '/files/image',
  UNLINK_FILE: '/files',
};

export const FILE_REQUEST_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
};

export const BASE_CHAINS_URL = '/chains';

export const BASE_SHOPS_URL = '/shops';

export const BASE_PRODUCERS_URL = '/producers';

export const BASE_TRANSPORTATION_MANAGER_URL = '/transportation-managers';

export const BASE_TRANSPORTATION_COMPANY_URL = '/transportation-companies';
