import { AxiosRequestConfig } from 'axios';

export const authorizationHeaderRequestInterceptor = (
  request: AxiosRequestConfig,
): AxiosRequestConfig => {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) (request.headers || {}).Authorization = `Bearer ${accessToken}`;
  return request;
};
