import { Theme } from '@mui/material';

export default (theme: Theme) => ({
  MuiTableCell: {
    defaultProps: {
      component: 'div',
    },
    styleOverrides: {
      root: {
        fontSize: 12,
        fontWeight: 500,
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderColor: theme.palette.grey[200],
      },
      head: {
        verticalAlign: 'top',
      },
    },
  },
});
