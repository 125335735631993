import authAxiosInstance from 'config/axios/auth.axios-instance';
import { BASE_TRANSPORTATION_MANAGER_URL } from 'constants/api.constants';
import {
  GetTransportationManagerByIdRequestArgumentsType,
  GetTransportationManagerByIdSuccessResponseType,
} from 'types/transportation-manager.types';

export const getTransportationManagerById = async (
  args: GetTransportationManagerByIdRequestArgumentsType,
): Promise<GetTransportationManagerByIdSuccessResponseType> => {
  const { params } = args;
  return await authAxiosInstance.get(`${BASE_TRANSPORTATION_MANAGER_URL}/${params.id}`);
};
