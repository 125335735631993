import { FC, ReactElement } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@nordic-firewood/ui-theme';
import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import { LayoutProvider } from 'context/LayoutContext';
import i18n from 'i18n';

type Props = {
  children: ReactElement;
  i18n: typeof i18n;
  store: any;
  snackbarProviderProps: SnackbarProviderProps;
  layoutProviderProps: any;
};

const Providers: FC<Props> = (props) => {
  const { children, i18n, store, snackbarProviderProps, layoutProviderProps } = props;

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <LayoutProvider value={layoutProviderProps}>
              <SnackbarProvider {...snackbarProviderProps}>{children}</SnackbarProvider>
            </LayoutProvider>
          </LocalizationProvider>
        </Provider>
      </ThemeProvider>
    </I18nextProvider>
  );
};

export default Providers;
