import { FC } from 'react';

import { Outlet, Navigate } from 'react-router-dom';

type Props = {
  isAuthenticated: boolean;
};

const PublicRoute: FC<Props> = (props) => {
  const { isAuthenticated } = props;

  if (isAuthenticated) return <Navigate to="/" />;
  return <Outlet />;
};

export default PublicRoute;
