import { grey } from '../colors';

export default {
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        color: grey[600],

        '&.Mui-focused:not(.Mui-error)': {
          color: grey[900],
        },
      },
    },
  },
};
