import { green } from '../colors';

export default {
  MuiInputAdornment: {
    styleOverrides: {
      root: {},
      positionEnd: {
        color: green[800],
      },
    },
  },
};
