import baseAxiosInstance from 'config/axios/base.axios-instance';
import { URL_AUTH } from 'constants/api.constants';
import {
  LogoutRequestArgumentsType,
  RefreshTokenRequestArgumentsType,
  RefreshTokenSuccessResponseType,
  SignInRequestArgumentsType,
  SignInSuccessResponseType,
} from 'types/auth.types';

export const signIn = async (
  args: SignInRequestArgumentsType,
): Promise<SignInSuccessResponseType> => {
  const { body } = args;

  return await baseAxiosInstance.post(URL_AUTH.SIGN_IN, body);
};

export const refreshToken = async (
  args: RefreshTokenRequestArgumentsType,
): Promise<RefreshTokenSuccessResponseType> => {
  const { body } = args;

  return await baseAxiosInstance.post(URL_AUTH.REFRESH_TOKEN, body);
};

export const logout = async (args: LogoutRequestArgumentsType): Promise<number> => {
  const { body } = args;

  return await baseAxiosInstance.post(URL_AUTH.LOGOUT, body);
};
