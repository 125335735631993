import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  signIn as signInRequest,
  logout as logoutRequest,
  refreshToken as refreshTokenRequest,
} from 'services/api/auth.api';
import {
  LogoutRequestArgumentsType,
  RefreshTokenRequestArgumentsType,
  RefreshTokenSuccessResponseType,
  SignInFailureResponseType,
  SignInRequestArgumentsType,
  SignInSuccessResponseType,
} from 'types/auth.types';
import { ResponseFailureInterface } from 'types/common.types';

export const signIn = createAsyncThunk<
  SignInSuccessResponseType,
  SignInRequestArgumentsType,
  SignInFailureResponseType
>('auth/sign-in', async (args, { rejectWithValue }) => {
  try {
    return await signInRequest(args);
  } catch (error) {
    return rejectWithValue(error as ResponseFailureInterface);
  }
});

export const refreshToken = createAsyncThunk<
  RefreshTokenSuccessResponseType,
  RefreshTokenRequestArgumentsType
>('auth/refresh-token', async (args) => {
  return await refreshTokenRequest(args);
});

export const logout = createAsyncThunk<number, LogoutRequestArgumentsType>(
  'auth/logout',
  async (args) => {
    return await logoutRequest(args);
  },
);
