import { ChainResponseInterface, ShopResponseInterface } from '@nordic-firewood/api-contracts';

import { FETCHING_STATUSES } from 'constants/common.constants';

type ChainState = {
  chains: {
    fetchingChainsStatus: string;
    data: ChainResponseInterface[];
    totalRecords: number;
    totalFilteredRecords: number;
  };
  chainShops: {
    fetchingChainShopsStatus: string;
    data: ShopResponseInterface[];
    totalRecords: number;
    totalFilteredRecords: number;
  };
};

const initialState: ChainState = {
  chains: {
    fetchingChainsStatus: FETCHING_STATUSES.IDLE,
    data: null,
    totalRecords: 0,
    totalFilteredRecords: 0,
  },
  chainShops: {
    fetchingChainShopsStatus: FETCHING_STATUSES.IDLE,
    data: null,
    totalRecords: 0,
    totalFilteredRecords: 0,
  },
};

export default initialState;
