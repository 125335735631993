import { createSlice } from '@reduxjs/toolkit';

import { FETCHING_STATUSES } from 'constants/common.constants';

import {
  getTransportationCompanyById as getTransportationCompanyByIdThunk,
  getAllTransportationCompanies as getAllTransportationCompaniesThunk,
  getTransportationCompanyManagers as getTransportationCompanyManagersThunk,
} from './transportation-company.actions';
import initialState from './transportation-company.state';

const transportationCompanySlice = createSlice({
  name: 'transportation-company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTransportationCompanyByIdThunk.pending, (state) => {
      state.company.fetchingTransportationCompanyStatus = FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(getTransportationCompanyByIdThunk.fulfilled, (state, action) => {
      state.company.fetchingTransportationCompanyStatus = FETCHING_STATUSES.SUCCESS;
      state.company.data = action.payload;
    });
    builder.addCase(getTransportationCompanyByIdThunk.rejected, (state) => {
      state.company.fetchingTransportationCompanyStatus = FETCHING_STATUSES.FAILURE;
      state.company.data = null;
    });
    builder.addCase(getAllTransportationCompaniesThunk.pending, (state) => {
      state.companies.fetchingTransportationCompaniesStatus = FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(getAllTransportationCompaniesThunk.fulfilled, (state, action) => {
      state.companies.fetchingTransportationCompaniesStatus = FETCHING_STATUSES.SUCCESS;
      state.companies.data = action.payload.data;
      state.companies.totalRecords = action.payload.totalRecords;
      state.companies.totalFilteredRecords = action.payload.totalFilteredRecords;
    });
    builder.addCase(getAllTransportationCompaniesThunk.rejected, (state) => {
      state.companies.fetchingTransportationCompaniesStatus = FETCHING_STATUSES.FAILURE;
      state.companies.data = null;
      state.companies.totalRecords = 0;
      state.companies.totalFilteredRecords = 0;
    });
    builder.addCase(getTransportationCompanyManagersThunk.pending, (state) => {
      state.companyManagers.fetchingCompanyManagersStatus = FETCHING_STATUSES.FETCHING;
    });
    builder.addCase(getTransportationCompanyManagersThunk.fulfilled, (state, action) => {
      state.companyManagers.fetchingCompanyManagersStatus = FETCHING_STATUSES.SUCCESS;
      state.companyManagers.data = action.payload.data;
    });
    builder.addCase(getTransportationCompanyManagersThunk.rejected, (state) => {
      state.companyManagers.fetchingCompanyManagersStatus = FETCHING_STATUSES.FAILURE;
      state.companyManagers.data = null;
    });
  },
});

const { reducer } = transportationCompanySlice;

export default reducer;
