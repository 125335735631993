import { lazy } from 'react';

import { Route, Routes } from 'react-router-dom';

import {
  ROUTE_ORDERS,
  ROUTE_ORDERS_CREATE_AND_UPDATE,
  ROUTE_PRODUCTS,
  ROUTE_PRODUCTS_CREATE,
} from 'constants/route.constants';

const ProductIndex = lazy(() => import('pages/Product/Index'));
const ProductCreate = lazy(() => import('pages/Product/Create'));

const OrderIndex = lazy(() => import('pages/Order/Index'));
const OrderCreateAndUpdate = lazy(() => import('pages/Order/CreateAndUpdate'));

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<ProductIndex />} />
      <Route path={ROUTE_PRODUCTS} element={<ProductIndex />} />
      <Route path={ROUTE_PRODUCTS_CREATE} element={<ProductCreate />} />
      <Route path={ROUTE_ORDERS} element={<OrderIndex />} />
      <Route
        path={`${ROUTE_ORDERS_CREATE_AND_UPDATE}/:tab/:id`}
        element={<OrderCreateAndUpdate />}
      />
    </Routes>
  );
};

export default PrivateRoutes;
