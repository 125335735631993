import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getProducerById as getProducerByIdRequest,
  getProducersByCompanyName as getProducersByCompanyNameRequest,
} from 'services/api/producer.api';
import {
  GetProducerByCompanyNameRequestArgumentsType,
  GetProducerByIdRequestArgumentsType,
  GetProducerByIdSuccessResponseType,
  GetProducersByCompanyNameSuccessResponseType,
} from 'types/producer.types';

export const getProducerById = createAsyncThunk<
  GetProducerByIdSuccessResponseType,
  GetProducerByIdRequestArgumentsType,
  null
>('producer/get-by-id', async (args) => {
  return await getProducerByIdRequest(args);
});

export const getProducersByCompanyName = createAsyncThunk<
  GetProducersByCompanyNameSuccessResponseType,
  GetProducerByCompanyNameRequestArgumentsType,
  null
>('producer/get-by-company-name', async (args) => {
  return await getProducersByCompanyNameRequest(args);
});
