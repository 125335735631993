import { Theme } from '@mui/material';

export default (theme: Theme) => ({
  MuiTableHead: {
    defaultProps: {
      component: 'div',
    },
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.grey[100],
      },
    },
  },
});
