const red = {
  50: '#ffe9ef',
  100: '#ffcbd3',
  200: '#fb9698',
  300: '#f06e73',
  400: '#ff4d4f',
  500: '#ff3934',
  600: '#ef2e33',
  700: '#dc1f2d',
  800: '#d01926',
  900: '#c10518',
};

export default red;
