import { grey } from '../colors';

export default {
  MuiTooltip: {
    defaultProps: {
      arrow: true,
    },
    styleOverrides: {
      tooltip: {
        padding: 8,
        fontSize: 12,
        fontWeight: 500,
        backgroundColor: grey[800],
      },
      arrow: {
        color: grey[800],
      },
    },
  },
};
