import authAxiosInstance from 'config/axios/auth.axios-instance';
import { BASE_TRANSPORTATION_COMPANY_URL } from 'constants/api.constants';
import {
  GetTransportationCompaniesRequestArgumentsType,
  GetTransportationCompaniesSuccessResponseType,
  getTransportationCompanyByIdRequestArgumentsInterface,
  GetTransportationCompanyByIdSuccessResponseType,
  GetTransportationCompanyManagersRequestArgumentsType,
  GetTransportationCompanyManagersSuccessResponseType,
} from 'types/transportation-company.types';

export const getTransportationCompanyById = async (
  args: getTransportationCompanyByIdRequestArgumentsInterface,
): Promise<GetTransportationCompanyByIdSuccessResponseType> => {
  const { params } = args;
  return await authAxiosInstance.get(`${BASE_TRANSPORTATION_COMPANY_URL}/${params.id}`);
};

export const getAllTransportationCompanies = async (
  args: GetTransportationCompaniesRequestArgumentsType,
): Promise<GetTransportationCompaniesSuccessResponseType> => {
  const { query } = args;
  return await authAxiosInstance.get(BASE_TRANSPORTATION_COMPANY_URL, { params: query });
};

export const getTransportationCompanyManagers = async (
  args: GetTransportationCompanyManagersRequestArgumentsType,
): Promise<GetTransportationCompanyManagersSuccessResponseType> => {
  const { params, query } = args;
  return await authAxiosInstance(
    `${BASE_TRANSPORTATION_COMPANY_URL}/${params.id}/transportation-managers`,
    { params: query },
  );
};
