import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

const selectChainState = (state: RootState) => state.chain;

export const selectFetchingChainsStatus = createSelector([selectChainState], (state) => {
  return state.chains.fetchingChainsStatus;
});
export const selectChains = createSelector([selectChainState], (state) => {
  return state.chains.data;
});

export const selectFetchingChainShopsStatus = createSelector([selectChainState], (state) => {
  return state.chainShops.fetchingChainShopsStatus;
});
export const selectChainShops = createSelector([selectChainState], (state) => {
  return state.chainShops.data;
});
