import { OrderResponseInterface } from '@nordic-firewood/api-contracts';

import { FETCHING_STATUSES } from 'constants/common.constants';
import { FormFieldsErrors } from 'types/common.types';

type OrderState = {
  creatingOrderStatus: string;
  updatingOrderStatus: string;
  errors: FormFieldsErrors | null;
  order: {
    fetchingOrderStatus: string;
    data: OrderResponseInterface;
  };
  orders: {
    fetchingAllOrdersStatus: string;
    data: OrderResponseInterface[];
    totalRecords: number;
  };
};

const initialState: OrderState = {
  creatingOrderStatus: FETCHING_STATUSES.IDLE,
  updatingOrderStatus: FETCHING_STATUSES.IDLE,
  errors: null,
  order: {
    fetchingOrderStatus: FETCHING_STATUSES.IDLE,
    data: null,
  },
  orders: {
    fetchingAllOrdersStatus: FETCHING_STATUSES.IDLE,
    data: null,
    totalRecords: 0,
  },
};

export default initialState;
