import authAxiosInstance from 'config/axios/auth.axios-instance';
import { BASE_SHOPS_URL } from 'constants/api.constants';
import { GetShopByIdRequestArgumentsType, GetShopByIdSuccessResponseType } from 'types/shop.types';

export const getShopById = async (
  args: GetShopByIdRequestArgumentsType,
): Promise<GetShopByIdSuccessResponseType> => {
  const { params } = args;

  return await authAxiosInstance.get(`${BASE_SHOPS_URL}/${params.id}`);
};
