const yellow = {
  50: '#f5f3ef',
  100: '#f8e6c1',
  200: '#fad991',
  300: '#fdce61',
  400: '#fec243',
  500: '#ffba34',
  600: '#ffae30',
  700: '#fa9a2e',
  800: '#fa8c2e',
  900: '#f76b28',
};

export default yellow;
