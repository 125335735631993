import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

const selectOrderState = (state: RootState) => state.order;

export const selectCreatingOrderStatus = createSelector([selectOrderState], (state) => {
  return state.creatingOrderStatus;
});
export const selectUpdatingOrderStatus = createSelector([selectOrderState], (state) => {
  return state.updatingOrderStatus;
});
export const selectFetchingOrdersStatus = createSelector([selectOrderState], (state) => {
  return state.updatingOrderStatus;
});
export const selectOrder = createSelector([selectOrderState], (state) => {
  return state.order.data;
});
export const selectAllOrders = createSelector([selectOrderState], (state) => {
  return state.orders.data;
});
export const selectOrdersTotalRecords = createSelector([selectOrderState], (state) => {
  return state.orders.totalRecords;
});
export const selectErrors = createSelector([selectOrderState], (state) => {
  return state.errors;
});
