import { createAsyncThunk } from '@reduxjs/toolkit';

import { getShopById as getShopByIdRequest } from 'services/api/shop.api';
import { GetShopByIdRequestArgumentsType, GetShopByIdSuccessResponseType } from 'types/shop.types';

export const getShopById = createAsyncThunk<
  GetShopByIdSuccessResponseType,
  GetShopByIdRequestArgumentsType,
  null
>('shop/get-by-id', async (args: any) => {
  return await getShopByIdRequest(args);
});
