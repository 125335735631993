import { Container, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as VerticalNordicFirewoodLogoIcon } from 'assets/icons/v-nordic-firewood-logo-white.svg';

import { Layout, Box } from './PublicLayout.styles';

const PublicLayout = (props) => {
  const { children } = props;

  const { t } = useTranslation();

  return (
    <Layout maxWidth={false}>
      <Container disableGutters maxWidth="lg" sx={{ height: '100%' }}>
        <Grid container sx={{ height: '100%' }}>
          <Grid item md={7}>
            <Box>
              <Stack direction="column" spacing={3}>
                <VerticalNordicFirewoodLogoIcon />
                <Typography variant="h2" color="grey.0" sx={{ maxWidth: 600 }}>
                  {t('Nordic Firewood — Marketplace for firewood and pellets')}
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item md={5}>
            <Box>{children}</Box>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default PublicLayout;
