import { CircularProgress } from '@mui/material';

import { Container } from './AppLoader.styles';

const AppLoader = () => {
  return (
    <Container>
      <CircularProgress size={100} thickness={6} value={25} />
    </Container>
  );
};

export default AppLoader;
