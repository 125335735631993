import { grey, red } from '../colors';

export default {
  MuiOutlinedInput: {
    defaultProps: {
      size: 'small' as const,
    },
    styleOverrides: {
      root: {
        fontWeight: 500,

        'label + &': {
          marginTop: 32,
        },

        '&.Mui-focused': {
          '.MuiInputAdornment-positionStart': {
            color: grey[900],
          },

          '.MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
          },
        },

        '&.MuiInputBase-readOnly': {
          paddingRight: 0,
          paddingLeft: 0,

          '.MuiInputBase-input': {
            paddingLeft: 0,
          },

          '.MuiInputAdornment-positionStart': {
            color: grey[800],
          },

          '.MuiInputAdornment-positionEnd': {
            color: grey[400],
          },

          '.MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
          },

          '.MuiSelect-select ~ .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
          },
        },
      },
      notchedOutline: {
        top: 0,
        borderColor: grey[400],

        legend: {
          display: 'none',
        },
      },
      multiline: {
        '.MuiInputAdornment-positionStart': {
          alignSelf: 'flex-start',
          marginTop: 11,
        },

        '.MuiInputAdornment-positionEnd': {
          alignSelf: 'flex-start',
          marginTop: 11,
        },
      },
      error: {
        '.MuiInputAdornment-positionStart': {
          color: grey[900],
        },

        '.MuiInputAdornment-positionEnd': {
          color: red[800],
        },
      },
    },
  },
};
