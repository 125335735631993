import { useEffect, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { colors } from '@nordic-firewood/ui-theme';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PublicLayout } from 'components/Layout/PublicLayout';
import { FETCHING_STATUSES } from 'constants/common.constants';
import { useAppDispatch, useAppSelector } from 'store';
import { clearErrors, selectErrors, selectFetchingSignInStatus, signIn } from 'store/auth';

import { Card } from './SignIn.styles';

const SignIn = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const fetchingSignInStatus = useAppSelector(selectFetchingSignInStatus);
  const errors = useAppSelector(selectErrors);

  const isSignInPending = fetchingSignInStatus === FETCHING_STATUSES.FETCHING;

  const { control, handleSubmit, setError } = useForm({
    mode: 'onChange',
    defaultValues: { email: '', password: '' },
  });

  const submit = (model) => dispatch(signIn({ body: model }));

  useEffect(() => {
    if (errors) {
      Object.entries(errors).forEach(([key, value]) => {
        setError(key as 'email' | 'password', { type: 'manual', message: value[0] });
      });
    }

    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch, errors, setError]);

  return (
    <PublicLayout>
      <Card component="form" onSubmit={handleSubmit(submit)}>
        <Typography variant="body1" color={colors.grey[900]} fontWeight={700} sx={{ mb: 1 }}>
          {t('Log in')}
        </Typography>
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              fullWidth
              size="small"
              variant="standard"
              margin="dense"
              error={Boolean(error)}
            >
              <OutlinedInput placeholder={t('Email or phone')} value={value} onChange={onChange} />
              {error && <FormHelperText>{error.message}</FormHelperText>}
            </FormControl>
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl
              fullWidth
              size="small"
              variant="standard"
              margin="dense"
              error={Boolean(error)}
            >
              <OutlinedInput
                type={showPassword ? 'text' : 'password'}
                placeholder={t('Password')}
                value={value}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={onChange}
              />
              {error && <FormHelperText>{error.message}</FormHelperText>}
            </FormControl>
          )}
        />
        <LoadingButton
          fullWidth
          variant="contained"
          type="submit"
          loading={isSignInPending}
          sx={{ mt: 1.5 }}
        >
          {t('Log In')}
        </LoadingButton>
      </Card>
    </PublicLayout>
  );
};

export default SignIn;
