import { ProductResponseInterface } from '@nordic-firewood/api-contracts';

import { FETCHING_STATUSES } from 'constants/common.constants';
import { FormFieldsErrors } from 'types/common.types';

type ProductState = {
  creatingProductStatus: string;
  errors: FormFieldsErrors | null;
  products: {
    fetchingProductsStatus: string;
    data: ProductResponseInterface[];
    totalRecords: number;
    totalFilteredRecords: number;
  };
  productsBySearch: {
    fetchingProductsBySearchStatus: string;
    data: ProductResponseInterface[];
    totalRecords: number;
    totalFilteredRecords: number;
  };
};

const initialState: ProductState = {
  creatingProductStatus: FETCHING_STATUSES.IDLE,
  errors: null,
  products: {
    fetchingProductsStatus: FETCHING_STATUSES.IDLE,
    data: null,
    totalRecords: 0,
    totalFilteredRecords: 0,
  },
  productsBySearch: {
    fetchingProductsBySearchStatus: FETCHING_STATUSES.IDLE,
    data: null,
    totalRecords: 0,
    totalFilteredRecords: 0,
  },
};

export default initialState;
