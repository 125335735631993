import authAxiosInstance from 'config/axios/auth.axios-instance';
import { URL_ORDER } from 'constants/api.constants';
import {
  CreateOrderRequestArgumentsType,
  CreateOrderSuccessResponseType,
  GetAllOrdersRequestArgumentsType,
  GetAllOrdersSuccessResponseType,
  GetOrderByIdRequestArgumentsType,
  GetOrderByIdSuccessResponseType,
  UpdateOrderRequestArgumentsType,
  UpdateOrderSuccessResponseType,
} from 'types/order.types';

export const createOrder = async (
  args: CreateOrderRequestArgumentsType,
): Promise<CreateOrderSuccessResponseType> => {
  const { body } = args;

  return await authAxiosInstance.post(URL_ORDER.CREATE, body);
};

export const updateOrder = async (
  args: UpdateOrderRequestArgumentsType,
): Promise<UpdateOrderSuccessResponseType> => {
  const { params, body } = args;

  return await authAxiosInstance.put(`${URL_ORDER.UPDATE}/${params.id}`, body);
};

export const getOrderById = async (
  args: GetOrderByIdRequestArgumentsType,
): Promise<GetOrderByIdSuccessResponseType> => {
  const { params } = args;

  return await authAxiosInstance.get(`${URL_ORDER.GET_BY_ID}/${params.id}`);
};

export const getAllOrders = async (
  args: GetAllOrdersRequestArgumentsType,
): Promise<GetAllOrdersSuccessResponseType> => {
  const { query } = args;

  return await authAxiosInstance.get(`${URL_ORDER.GET_ALL}`, { params: query });
};
