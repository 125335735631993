declare module '@mui/material/FormControl' {
  interface FormControlPropsColorOverrides {
    dark: true;
  }
}

export default {
  MuiFormControl: {
    defaultProps: {
      color: 'dark' as const,
    },
    styleOverrides: {
      root: {},
    },
  },
};
