import { useEffect, useState } from 'react';

import { Container } from '@mui/material';
import { Route, Routes } from 'react-router-dom';

import { PrivateRoutes } from 'app/routes';
import AppLoader from 'components/Layout/AppLoader';
import PrivateRoute from 'components/Layout/PrivateRoute';
import PublicRoute from 'components/Layout/PublicRoute';
import { REFRESH_TOKEN_KEY } from 'constants/common.constants';
import { ROUTE_SIGN_IN } from 'constants/route.constants';
import { SignIn } from 'pages/SignIn';
import { useAppDispatch, useAppSelector } from 'store';
import { refreshToken, selectIsAuthenticated } from 'store/auth';

const Auth = () => {
  const dispatch = useAppDispatch();

  const [isPendingAuthorization, setIsPendingAuthorization] = useState<boolean>(true);

  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  useEffect(() => {
    const getRefreshToken = async () => {
      const token = localStorage.getItem(REFRESH_TOKEN_KEY);

      if (token) {
        await dispatch(refreshToken({ body: { token } }));
        return setIsPendingAuthorization(false);
      }

      return setIsPendingAuthorization(false);
    };

    getRefreshToken();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPendingAuthorization) return <AppLoader />;

  return (
    <Container disableGutters maxWidth={false} sx={{ height: '100vh' }}>
      <Routes>
        <Route element={<PublicRoute isAuthenticated={isAuthenticated} />}>
          <Route path={ROUTE_SIGN_IN} element={<SignIn />} />
        </Route>
        <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
          <Route path="/*" element={<PrivateRoutes />} />
        </Route>
      </Routes>
    </Container>
  );
};

export default Auth;
