import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

const selectTransportationCompanyState = (state: RootState) => state.transportationCompany;

export const selectFetchingTransportationCompanyStatus = createSelector(
  [selectTransportationCompanyState],
  (state) => {
    return state.company.fetchingTransportationCompanyStatus;
  },
);
export const selectTransportationCompany = createSelector(
  [selectTransportationCompanyState],
  (state) => {
    return state.company.data;
  },
);

export const selectFetchingTransportationCompaniesStatus = createSelector(
  [selectTransportationCompanyState],
  (state) => {
    return state.companies.fetchingTransportationCompaniesStatus;
  },
);
export const selectTransportationCompanies = createSelector(
  [selectTransportationCompanyState],
  (state) => {
    return state.companies.data;
  },
);

export const selectFetchingCompanyManagersStatus = createSelector(
  [selectTransportationCompanyState],
  (state) => {
    return state.companyManagers.fetchingCompanyManagersStatus;
  },
);
export const selectTransportationCompanyManagers = createSelector(
  [selectTransportationCompanyState],
  (state) => {
    return state.companyManagers.data;
  },
);
